"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const PropertyDetailsContext_1 = require("./PropertyDetailsContext");
const PropertyDetailsContextProvider = ({ children, assetPrice, glossary, triggerSignUpModal = () => { }, triggerCalendlyBookingModal = () => { }, triggerWaitlistModal = () => { }, }) => {
    const [currentDownPayment, setCurrentDownPayment] = (0, react_1.useState)(PropertyDetailsContext_1.PDP_CXT_DEFAULT.downPayment);
    const [currentInterest, setCurrentInterest] = (0, react_1.useState)(PropertyDetailsContext_1.PDP_CXT_DEFAULT.interest);
    const [currIOPeriod, setCurrIOPeriod] = (0, react_1.useState)(PropertyDetailsContext_1.PDP_CXT_DEFAULT.ioPeriod);
    const [currNetIncome, setNetIncome] = (0, react_1.useState)(PropertyDetailsContext_1.PDP_CXT_DEFAULT.netIncome);
    const [currNetExpenses, setNetExpenses] = (0, react_1.useState)(PropertyDetailsContext_1.PDP_CXT_DEFAULT.netExpenses);
    const [currLoanTerm, setLoanTerm] = (0, react_1.useState)(PropertyDetailsContext_1.PDP_CXT_DEFAULT.loanTerm);
    const [currCaprate, setCaprate] = (0, react_1.useState)(PropertyDetailsContext_1.PDP_CXT_DEFAULT.caprate);
    const [currIRR, setIRR] = (0, react_1.useState)(PropertyDetailsContext_1.PDP_CXT_DEFAULT.irr);
    const [currCashFlow, setCashFlow] = (0, react_1.useState)(PropertyDetailsContext_1.PDP_CXT_DEFAULT.cashFlow);
    const [currAcquistionEquity, setAcquisitionEquity] = (0, react_1.useState)(PropertyDetailsContext_1.PDP_CXT_DEFAULT.acquisitionEquity);
    const [currDispositionProceeds, setDispositionProceeds] = (0, react_1.useState)(PropertyDetailsContext_1.PDP_CXT_DEFAULT.dispositionProceeds);
    const [currYearOneMortgagePayments, setYearOneMortgagePayments] = (0, react_1.useState)(PropertyDetailsContext_1.PDP_CXT_DEFAULT.yearOneMortgagePayments);
    const [currCashOffset, setCashOffset] = (0, react_1.useState)(PropertyDetailsContext_1.PDP_CXT_DEFAULT.cashOffset);
    return ((0, jsx_runtime_1.jsx)(PropertyDetailsContext_1.PropertyDetailsContext.Provider, Object.assign({ value: {
            acquisitionEquity: currAcquistionEquity,
            assetPrice: assetPrice,
            caprate: currCaprate,
            cashFlow: currCashFlow,
            cashOffset: currCashOffset,
            dispositionProceeds: currDispositionProceeds,
            downPayment: currentDownPayment,
            interest: currentInterest,
            ioPeriod: currIOPeriod,
            irr: currIRR,
            loanTerm: currLoanTerm,
            netExpenses: currNetExpenses,
            netIncome: currNetIncome,
            yearOneMortgagePayments: currYearOneMortgagePayments,
            updateDownPayment: setCurrentDownPayment,
            updateInterest: setCurrentInterest,
            updateIOPeriod: setCurrIOPeriod,
            updateNetIncome: setNetIncome,
            updateNetExpenses: setNetExpenses,
            updateLoanTerm: setLoanTerm,
            updateCaprate: setCaprate,
            updateIRR: setIRR,
            updateCashFlow: setCashFlow,
            updateAcquisitionEquity: setAcquisitionEquity,
            updateDispositionProceeds: setDispositionProceeds,
            updateYearOneMortgagePayments: setYearOneMortgagePayments,
            updateCashOffset: setCashOffset,
            glossary,
            triggerSignUpModal,
            triggerCalendlyBookingModal,
            triggerWaitlistModal,
        } }, { children: children })));
};
exports.default = PropertyDetailsContextProvider;
