"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const components_1 = require("@kepler/components");
const server_1 = require("react-dom/server");
const react_1 = require("react");
const PropertyDetailsContext_1 = require("@components/PropertyDetails/context/PropertyDetailsContext");
function PdpTermTooltip({ termKey, dynamicVal, }) {
    var _a;
    const { glossary } = (0, react_1.useContext)(PropertyDetailsContext_1.PropertyDetailsContext);
    const termDefined = ((_a = glossary === null || glossary === void 0 ? void 0 : glossary[termKey]) === null || _a === void 0 ? void 0 : _a.dt) ? glossary[termKey] : undefined;
    const body = (0, react_1.useMemo)(() => {
        if (!termDefined) {
            return '';
        }
        if (typeof dynamicVal === 'string') {
            return termDefined.dd.replace('{{dyn}}', dynamicVal);
        }
        return termDefined.dd;
    }, [dynamicVal, termDefined]);
    if (!termDefined)
        return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (termKey.charAt(0).toUpperCase() + termKey.substring(1)).replaceAll('_', ' ') }));
    const contentHtml = (0, server_1.renderToStaticMarkup)((0, jsx_runtime_1.jsxs)("div", Object.assign({ className: "text-start p-3" }, { children: [(0, jsx_runtime_1.jsxs)("p", Object.assign({ className: "display-6 fw-semibold" }, { children: [(0, jsx_runtime_1.jsx)("span", { children: (0, jsx_runtime_1.jsx)("i", { className: "bi bi-info-circle me-2" }) }), termDefined.dt] })), (0, jsx_runtime_1.jsx)("span", Object.assign({ className: "display-linebreak" }, { children: body }))] })));
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [termDefined.dt, (0, jsx_runtime_1.jsx)(components_1.Tooltip, { darkMode: true, as: 'i', content: contentHtml, className: "small fs-6 ms-2 bi bi-info-circle-fill" })] }));
}
exports.default = PdpTermTooltip;
