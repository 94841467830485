"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_toastify_1 = require("react-toastify");
require("react-toastify/dist/ReactToastify.css");
const Message = ({ message, tag, level, level_tag, extra_tags, }) => {
    console.log(message, 'tag is', tag, 'level is:', level, level_tag, extra_tags);
    const Msg = ({ message }) => {
        return (0, jsx_runtime_1.jsx)("p", Object.assign({ className: "mb-0 small" }, { children: message }));
    };
    const toastOpts = {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: react_toastify_1.Slide,
        toastId: message,
    };
    (0, react_1.useEffect)(() => {
        switch (tag) {
            case 'success':
                react_toastify_1.toast.success((0, jsx_runtime_1.jsx)(Msg, { message: message }), toastOpts);
                break;
            case 'warning':
                react_toastify_1.toast.warning((0, jsx_runtime_1.jsx)(Msg, { message: message }), toastOpts);
                break;
            case 'danger':
                react_toastify_1.toast.error((0, jsx_runtime_1.jsx)(Msg, { message: message }), toastOpts);
                break;
            case 'info':
                react_toastify_1.toast.info((0, jsx_runtime_1.jsx)(Msg, { message: message }), toastOpts);
                break;
            default:
                react_toastify_1.toast.info((0, jsx_runtime_1.jsx)(Msg, { message: message }), toastOpts);
                break;
        }
    }, [message]);
    return (0, jsx_runtime_1.jsx)(react_toastify_1.ToastContainer, {});
};
exports.default = Message;
