"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const react_stripe_js_1 = require("@stripe/react-stripe-js");
const StripeErrorMessages = {
    card_error: 'Your card is invalid or cannot be processed. Please try again later or with a different card.',
    validation_error: 'Your form cannot be submitted. Please check your form details and try again.',
    default: 'Your payment cannot be processed. Please check your details and try again. ',
};
const useStripeCheckout = ({ subscriptionId, termsAccepted, }) => {
    const [isSubmitting, setIsSubmitting] = (0, react_1.useState)(false);
    const [stripeErr, setStripeErr] = (0, react_1.useState)();
    const stripe = (0, react_stripe_js_1.useStripe)();
    const elements = (0, react_stripe_js_1.useElements)();
    const handleSubmit = (e) => __awaiter(void 0, void 0, void 0, function* () {
        if (!termsAccepted) {
            setStripeErr({
                error: {
                    title: 'Something went wrong',
                    message: 'Form Incomplete. Please accept the terms to continue',
                },
            });
            return;
        }
        try {
            e.preventDefault();
            setIsSubmitting(true);
            if (!stripe || !elements) {
                return;
            }
            const { error } = yield stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: `${window.origin}/wealthbuilder/success?subscription_id=${subscriptionId}`,
                },
            });
            if (error) {
                setStripeErr({
                    error: {
                        title: 'Something went wrong.',
                        message: StripeErrorMessages[error.type] || StripeErrorMessages.default,
                    },
                });
            }
        }
        catch (error) {
            console.error('An error occured with Stripe', error);
            setStripeErr({
                error: {
                    title: 'Something went wrong.',
                    message: 'Please go back and try again.',
                },
            });
        }
        finally {
            setIsSubmitting(false);
        }
    });
    return {
        handleSubmit,
        isSubmitting,
        stripeErr,
    };
};
exports.default = useStripeCheckout;
