"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Collapse = exports.Tooltip = exports.Carousel = exports.Dropdown = exports.Modal = exports.Alert = void 0;
const bootstrap_1 = require("bootstrap");
Object.defineProperty(exports, "Alert", { enumerable: true, get: function () { return bootstrap_1.Alert; } });
Object.defineProperty(exports, "Dropdown", { enumerable: true, get: function () { return bootstrap_1.Dropdown; } });
Object.defineProperty(exports, "Modal", { enumerable: true, get: function () { return bootstrap_1.Modal; } });
Object.defineProperty(exports, "Carousel", { enumerable: true, get: function () { return bootstrap_1.Carousel; } });
Object.defineProperty(exports, "Tooltip", { enumerable: true, get: function () { return bootstrap_1.Tooltip; } });
Object.defineProperty(exports, "Collapse", { enumerable: true, get: function () { return bootstrap_1.Collapse; } });
